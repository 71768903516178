import React from "react"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import PropTypes from "prop-types"
import SEO from "../components/seo"

// Utilities
// import kebabCase from 'lodash/kebabCase'

// Components
import { Link, graphql } from "gatsby"

const TagsPage = ({
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title, description },
    },
  },
}) => (
  <div>
    <Layout title="Shanejix Blog" pageName="Tags">
      <SEO title="tags" />
      <Helmet
        htmlAttributes={{ lang: "en" }}
        meta={[{ name: "description", content: description }]}
        title={title}
      />
      <article
        style={{
          // border: `1px solid black`,
          borderRadius: `3px`,
          paddingTop: '3.5rem',
          marginBottom: `0.5rem`,
          // background: `white`,
          fontSize: `1rem`,
        }}
      >
        <h2>Tags</h2>
        <p>All articles classified through TAG each tag can view details.</p>
        <ul>
          {group.map((tag, idx) => (
            <li key={idx}>
              {/* <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}> */}
              <Link to={`/tags/${tag.fieldValue}/`}>
                {tag.fieldValue} ({tag.totalCount})
              </Link>
            </li>
          ))}
        </ul>
      </article>
    </Layout>
  </div>
)

TagsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
}

export default TagsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
